@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
  possibly useful for adding 
  more font weights 
  https://github.com/wonderunit/font-thicccboi#weights 
*/
@font-face {
  font-family: "THICCCBOI";
  src: url("../public/assets/fonts/thiccboi/THICCCBOI-Bold.woff2");
  font-weight: 700;
}
/* 
.flex {
  display: flex;
  gap: 10px;
} */

/* supplied by design agency */

@font-face {
  font-family: "Graphik";
  src: url("../public/assets/fonts/graphik/GraphikThin.otf");
  font-weight: 100;
}
@font-face {
  font-family: "Graphik";
  src: url("../public/assets/fonts/graphik/GraphikExtralight.otf");
  font-weight: 200;
}
@font-face {
  font-family: "Graphik";
  src: url("../public/assets/fonts/graphik/GraphikLight.otf");
  font-weight: 300;
}
@font-face {
  font-family: "Graphik";
  src: url("../public/assets/fonts/graphik/GraphikRegular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Graphik";
  src: url("../public/assets/fonts/graphik/GraphikMedium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Graphik";
  src: url("../public/assets/fonts/graphik/GraphikSemibold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Graphik";
  src: url("../public/assets/fonts/graphik/GraphikBold.otf");
  font-weight: 700;
}

/* no "Extra Bold"/800 Included */

@font-face {
  font-family: "Graphik";
  src: url("../public/assets/fonts/graphik/GraphikBlack.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Graphik";
  src: url("../public/assets/fonts/graphik/GraphikSuper.otf");
  font-weight: 950;
}

/* 
  Font weights according to: https://www.figma.com/file/02FlSaIiI5nKi3aXMF0jAJ/External-%2F-Navier-Reactor?node-id=1511%3A9192
  Note: font colors there are not accurate
  Note: it may or may not be "controversial" to set defaults here
  see: https://tailwindcss.com/docs/adding-base-styles
*/

@layer base {
  html {
    @apply bg-gradient-to-b from-top-blue to-bottom-blue text-secondary-black-500;
  }
  body {
    @apply font-graphik text-grayscale-900 text-secondary-black-500;
  }
  h1 {
    @apply text-5xl leading-[52.8px] font-bold text-secondary-black-500;
  }
  h2 {
    @apply text-[40px] leading-11 font-bold text-secondary-black-500;
  }
  h3 {
    @apply text-[32px] leading-10 font-bold tracking-[0.2px] text-secondary-black-500;
  }
  h4 {
    @apply text-2xl leading-[31.2px] font-bold tracking-[0.2px] text-secondary-black-500;
  }
  h5 {
    @apply text-xl leading-[27px] font-bold tracking-[0.2px] text-secondary-black-500;
  }
  h6 {
    @apply text-lg leading-[24.3px] font-bold tracking-[0.2px] text-secondary-black-500;
  }

  p {
    @apply text-medium text-secondary-black-500;
  }

  a {
    @apply text-primary-blue-600;
  }
  a:hover {
    @apply no-underline text-primary-blue-500;
  }
  a:active {
    @apply text-primary-blue-400;
  }
}

a {
  background-color: transparent;
  transition: color 0.3s;
}

@layer components {
  .hide-spinners::-webkit-outer-spin-button,
  .hide-spinners::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* here, we opt to preserve the TW default text sizes, and use
  text size names that match the Figma */
  .text-xsmall {
    @apply text-[10px] leading-[15px] tracking-[0.4px];
  }
  .text-small {
    @apply text-xs leading-[18px] tracking-[0.4px];
  }
  .text-medium {
    @apply text-[14px] leading-[21px] tracking-[0.2px];
  }

  .text-16 {
    @apply text-[16px] leading-[21px] tracking-[0.2px];
  }
  .text-large {
    @apply text-base leading-6 tracking-[0.2px];
  }
  .text-xlarge {
    @apply text-lg leading-[25.2px] tracking-[0.1px];
  }

  .text-xxlarge {
    @apply text-[22px] leading-[21px] tracking-[0.2px];
  }

  .text-xxxlarge {
    @apply text-[30px] leading-[21px] tracking-[0.2px];
  }

  .text-verylarge {
    @apply text-[42px] leading-[21px] tracking-[0.2px];
  }
}
